<template>
  <div class="container" v-if="this.filteredFullCart.length === 0">

    <h2 class="headline--padded text-center" v-html="$t('emptyCart')"></h2>
    <div class="nextItem">
<!--  <button class="btn btn--lg btn--primary btn--rounded" :class="[{ 'disabled' : false }]" @click="navigateToHome()">{{ $t('addItem') }}</button> -->
      <button 
        class="btn btn--lg btn--primary btn--rounded" 
        :class="[{ 'disabled' : false }]" 
        @click="navigateToHome()">
      <div class="nextItemSymbol">+</div> <div class="nextItemText">{{ $t('addItem') }}</div>
      </button>
    </div>
  </div>
  
  <div class="container" v-else >

    <div class="sendMailAround" v-show="sendMailShow">
      <div class="sendMailShadow" @click="sendmailShow = false"></div>
      <div v-show="$route.name === 'order'" class="sendMail">
       <div class="cartItemDelete"  @click="sendMailShow = false"></div>
       <div class="sendMailTitle">{{ $t('titleEmailSend') }}</div>
       <div class="sendMailSubTitle">{{ $t('subTitleEmailSend') }}</div>
       <div class="sendMailInfo">
         <input    v-model="email" :placeholder="$t('emailPlaceholder')" type="text" class="sendMailInput"    id="email">
         <input    v-model="name"  :placeholder="$t('namePlaceholder')"  type="text" class="sendMailInput"    id="name">
         <textarea v-model="text"  :placeholder="$t('textPlaceholder')"              class="sendMailTextArea" id="text"></textarea>
         <div class="sendPhoneSubTitle1">{{ $t('subTitlePhoneSend1') }}</div>
         <div class="sendPhoneSubTitle2">{{ $t('subTitlePhoneSend2') }}</div>
         <input    v-model="phone" :placeholder="$t('phonePlaceholder')" type="tel"  class="sendPhoneInput"   id="phone">

         <button 
          class="sendMailButton" 
          :class="[{ 'disabled' : false }]" 
          @click="sendMail">{{ $t('send') }}</button>
       </div>
      </div>
    </div>
    <div v-show="mailsend" class="loaderShadow"><div class="loaderSVG"><div class="loaderText">Odeslání e-mailu může chvíli trvat, proto prosím počkejte</div></div></div>

    <div class="sendMailHeadLineBlock">
      <h2 class="sendMailHeadline text-center" v-html="$t('orderTitle')"></h2>
      <table class="sendMailTable">
       <tr v-show="$route.name === 'order'" class="sendMailTr">
        <td colspan="2" class="sendMailTd">
         <button
          class="sendButton"
          :class="[{ 'disabled' : false }]"
          @click="sendMailShow=true"><div class="sendButtonSymbol"> </div> <div class="sendButtonText">{{ $t('sendMail') }}</div></button>
         </td>
        </tr>
       </table>
    </div>
    <table class="table table--order">
      <thead>
        <tr>
          <th v-html="$t('table.head.productNumber')"></th><th v-html="$t('table.head.image')"></th>
          <th v-html="$t('table.head.description')"></th><th v-html="$t('table.head.available')"></th>
          <th v-html="$t('table.head.itemPrice')"></th><th v-html="$t('table.head.count')"></th>
          <th v-html="$t('table.head.price')"></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="table__row" v-for="(cartItem, index) in filteredFullCart" v-bind:key="index">
          <td class="cartNumber text-center" v-html="$t('table.body.productNumber', { value: getOrderNum(index) })"></td>
          <td class="cartProduct text-center">
            <Preview :okna="getOkna({ variation: cartItem.variation, 
        			      orientation: cartItem.orientation, 
        			      width: cartItem.size.width, 
        			      height: cartItem.size.height, 
        			      canvasWidth: 2000,
        			      side: 'front',
        			      textureImage: cartItem.windowfaces['front'] !== null ? getStorageUrl(cartItem.windowfaces['front'].image) : null, 
        			      cartItemNumber: index
        			    })"
            >
            </Preview>
          </td>
          <td class="cartDescription">
            <p class="variation__property">
              <label class="variation__label" v-html="$t('summaryLabelName')"></label><span class="variation__value" v-html="cartItem.variation.type">
            </p>
            <p class="variation__property">
              <label class="variation__label" v-html="$t('summaryLabelWidth')"></label><span class="variation__value">{{ cartItem.size.width }}</span>
            </p>
            <p class="variation__property">
              <label class="variation__label" v-html="$t('summaryLabelHeight')"></label><span class="variation__value">{{ cartItem.size.height }}</span>
            </p>
            <p v-if="hasOrientation(cartItem.variation)" class="variation__property">
              <label class="variation__label" v-html="$t('summaryLabelOrientation')"></label><span  class="variation__value" v-html="$t(`summary.value.${cartItem.orientation}`)"></span>
            </p>
            <div v-if="cartItem.profile !== null">
              <p class="variation__property" v-if="cartItem.profile && cartItem.profile.name">
                <label class="variation__label" v-html="$t('profileLabel')"></label><span class="variation__value">{{ cartItem.profile.name }}</span>
              </p>
            </div>
            <div v-if="cartItem.windowfaces['front'] !== null">
              <p class="variation__property" v-if="cartItem.windowfaces['front'] && cartItem.windowfaces['front'].name">
                <label class="variation__label" v-html="$t(`color.label.front`)"></label><span class="variation__value">{{ cartItem.windowfaces['front'].name }}</span>
              </p>
            </div>
            <div v-if="cartItem.windowfaces['back'] !== null">
              <p class="variation__property" v-if="cartItem.windowfaces['back'] && cartItem.windowfaces['back'].name">
                <label class="variation__label" v-html="$t(`color.label.back`)"></label><span class="variation__value">{{ cartItem.windowfaces['back'].name }}</span>
              </p>
            </div>
            <div v-if="cartItem.glass !== null">
              <p class="variation__property" v-if="cartItem.glass && cartItem.glass.name">
                <label class="variation__label" v-html="$t('glassLabel')"></label><span class="variation__value">{{ cartItem.glass.name }}</span>
              </p>
            </div>
            <div v-for="(accessory, accessoryType) in cartItem.accessories" v-bind:key="accessoryType">
<!--
              <p class="variation__property" v-if="accessory && accessory.color && accessory.color.name && accessoryType !== 'handle'">
                <label class="variation__label" v-html="$t(`accessories.shorts.${accessoryType}`)"></label>
                <span class="variation__value"  v-if="(accessoryType === 'inner' || accessoryType === 'outer') && accessory && accessory.length && accessory.depth">{{ accessory.length }} &times; {{ accessory.depth }}, </span>
                <span class="variation__value"  v-if="accessory && accessory.color">{{ accessory.color.name }}</span>
              </p>
-->
<!--
              <p class="variation__property" v-if="(accessory && accessory.color && accessory.color.name) && (getMeta(cartItem, 0, index).cover.front || getMeta(cartItem, 0, index).cover.back)">
                <label class="variation__label" v-html="$t('color.cover')"></label>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'cover', 'front', index)" style="display: inline-block; vertical-align: top; margin-right: 10px;">{{ cartItem.accessories.cover.front.name }}</span>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'cover', 'back', index)">{{ cartItem.accessories.cover.back.name }}</span>
              </p>
-->
<!--
              <p class="variation__property" v-if="(accessory && accessory.color && accessory.color.name) && (getMeta(cartItem, 0, index).coverdrain.front || getMeta(cartItem, 0, index).coverdrain.back)">
                <label class="variation__label" v-html="$t('color.coverdrain')"></label>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'coverdrain', 'front', index)" style="display: inline-block; vertical-align: top; margin-right: 10px;">{{ cartItem.accessories.coverdrain.front.name }}</span>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'coverdrain', 'back', index)" >{{ cartItem.accessories.coverdrain.back.name }}</span>
              </p>
-->

              <p class="variation__property" v-if="accessory && accessory.color && accessory.color.name && accessoryType !==  'handle'">
                <label class="variation__label" v-html="$t(`accessories.shorts.${accessoryType}`)"></label>
                <span>
                  <span class="variation__value"  v-if="(accessoryType === 'inner' || accessoryType === 'outer') && accessory && accessory.length && accessory.depth">
                   <div style="white-space: nowrap;">{{ accessory.length }} &times; {{ accessory.depth }},</div>
                   <div>{{ accessory.color.name }}</div>
                </span>
              </p>

              <p class="variation__property" v-if="accessoryType ===  'cover' &&  (getMeta(cartItem, 0, index).cover.front || getMeta(cartItem, 0, index).cover.back)">
                <label class="variation__label" v-html="$t('color.cover')"></label>
                <span>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'cover', 'front', index)" style="display: inline-block; vertical-align: top; margin-right: 10px;">{{ cartItem.accessories.cover.front.name }}</span>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'cover', 'back', index)">{{ cartItem.accessories.cover.back.name }}</span>
                </span>
              </p>

              <p class="variation__property" v-if="accessoryType ===  'handle' && (checkAcc(cartItem, 'handle', 'front', index) || checkAcc(cartItem, 'handle', 'back', index))">
                <label class="variation__label" v-html="$t('color.handle')"></label>
                <span>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'handle', 'front', index)" style="display: inline-block; vertical-align: top; margin-right: 10px;">{{ $t('color.front.handle') }}: {{ cartItem.accessories.handle.front.name }}</span>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'handle', 'back', index)"  style="display: inline-block; vertical-align: top; margin-right: 10px;">{{ $t('color.back.handle')  }}: {{ cartItem.accessories.handle.back.name  }}</span>
                <span v-if="accessoryType === 'handle'" style="display: inline-block; vertical-align: top;"> {{ getNumberOfHandles(cartItem) }}&times; </span>
                </span>
              </p>

              <p class="variation__property" v-if="accessoryType ===  'coverdrain' && (getMeta(cartItem, 0, index).coverdrain.front || getMeta(cartItem, 0, index).coverdrain.back)">
                <label class="variation__label" v-html="$t('color.coverdrain')"></label>
                <span>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'coverdrain', 'front', index)" style="display: inline-block; vertical-align: top; margin-right: 10px;">{{ cartItem.accessories.coverdrain.front.name }}</span>
                <span class="variation__value"  v-if="checkAcc(cartItem, 'coverdrain', 'back', index)" >{{ cartItem.accessories.coverdrain.back.name }}</span>
                </span>
              </p>

              <p class="variation__property" v-if="accessoryType ===  'other' && getOther('blind', cartItem)">
                <label class="variation__label" v-html="$t('accessories.other.blind')"></label>                
                <span class="variation__value" v-if="getOther('blind', cartItem)" style="display: inline-block; vertical-align: top; margin-right: 10px;">Ano - poslat nabídku</span>
              </p>

              <p class="variation__property" v-if="accessoryType ===  'other' && getOther('insectnet', cartItem)">
                <label class="variation__label" v-html="$t('accessories.other.insectnet')"></label>                
                <span class="variation__value" v-if="getOther('insectnet', cartItem)" style="display: inline-block; vertical-align: top; margin-right: 10px;">Ano - poslat nabídku</span>
              </p>
            </div>
            
            <div v-if="cartItem.accessories && cartItem.accessories.inner && cartItem.accessories.inner.color && cartItem.accessories.inner.color.covers">
              <p class="variation__property" v-html="$t(`accessories.shorts.inner_covers`)"></p>
            </div>
            <div v-if="cartItem.accessories && cartItem.accessories.outer && cartItem.accessories.outer.color && cartItem.accessories.outer.color.covers">
              <p class="variation__property" v-html="$t(`accessories.shorts.outer_covers`)"></p>
            </div>
            <div v-if="getItemWeight(cartItem)" class="productWeight">
              <p class="variation__property">
                <label class="variation__label" v-html="$t('productWeight')"></label><span class="variation__value" v-html="getItemWeight(cartItem)"></span>
              </p>
            </div>
          </td>

          <td class="cartAvailable text-center">
           <div class="cartAvailableTitle">Dostupnost</div>
           <div class="itemAvailability" v-if="cartItem.category">{{ cartItem.category.available }}</div>
          </td>

          <td class="cartItemPrice text-center">
           <div class="cartItemPriceTitle">Jedn. cena</div>
           <div class="cartItemPriceTitleNoVat">bez DPH</div>
           <div v-html="$t('priceVat', { value: currencyFormatNum(getItemPriceOrder(cartItem, 1, cartItem.size.width, cartItem.size.height, 'wizardOrder-1')) })"></div>
           <div class="cartItemPriceTitleVat">s DPH</div>
           <div v-html="$t('priceVat', { value: currencyFormatNum(countWithVat(getItemPriceOrder(cartItem, 1, cartItem.size.width, cartItem.size.height, 'wizardOrder-3'))) })"></div>
          </td>
          <td class="cartQuantity text-center">
	    <div class="cartQuantityBlock">
              <div class="cartQuantityUp" @click="cartQuantity('up', cartItem)"></div>
	      <input type="text" class="cartQuantityInput" v-model="cartItem.count"/>
              <div v-if="cartItem.count > 1" class="cartQuantityDown" @click="cartQuantity('down', cartItem)"></div>
              <div v-else                    class="cartQuantityDown" style="cursor: auto; opacity: 0.2;"></div>

            </div>
          </td>
          <td class="cartPrice text-center">
           <div class="cartPriceTitle">Celkem</div>
           <div class="cartPriceTitleNoVat">bez DPH</div>
           <div v-html="$t('priceVat', { value: currencyFormatNum(getItemPriceOrder(cartItem, cartItem.count || 1, cartItem.size.width, cartItem.size.height, 'wizardOrder-3')) })"></div>
           <div class="cartPriceTitleVat">s DPH</div>
           <div v-html="$t('priceVat', { value: currencyFormatNum(countWithVat(getItemPriceOrder(cartItem, cartItem.count || 1, cartItem.size.width, cartItem.size.height, 'wizardOrder-4'))) })"></div>
          </td>
          <td class="cartEditItem text-center">
            <div class="cartItemDelete" @click="deleteItem(cartItem)"></div>
            <button class="buttonEditOrder" :class="[{ 'disabled' : false }]" @click="edit(cartItem)"   v-html="$t('edit')"></button>
          </td>
        </tr>

	<tr v-if='colorSurcharge != 0'>
	 <td class="colorSurchargeTitle">Příplatek za barvu</td>
	 <td class="colorSurchargeNote" colspan="5">Příplatek je účtován v případě, že máte v objednávce barvu ze skupiny 2 a součet všech kusů s touto barvou nepřekročí 15 ks. Příplatek se Vám automaticky odečte, pokud máte v součtu všech produktů v objednávce více jak 15 ks ve stejné barvě ze skupiny 2 v totožném profilu.</td>
	 <td class="colorSurchargePrice">
	 <div class="colorSurchargePriceNoVat">bez DPH</div>
	 {{ colorSurchargeValue * colorSurcharge }} Kč
	 <div class="colorSurchargePriceVat">s DPH</div>
	 {{ currencyFormatNum(countWithVat(colorSurchargeValue * colorSurcharge)) }} Kč
	 </td>
	 <td></td>
	</tr>

      </tbody>
    </table>
    
    <div class="nextItem">
      <button 
       class="btn btn--lg btn--primary btn--rounded" 
       :class="[{ 'disabled' : false }]" 
       @click="another"><div class="nextItemSymbol">+</div> <div class="nextItemText">{{ $t('addAnother') }}</div></button>
    </div>

    <table class="summaryTable">
<!--       <tr v-show="$route.name === 'order'" class="sendMailTr">
        <td colspan="2" class="sendMailTd">
         <button 
          class="sendButton" 
          :class="[{ 'disabled' : false }]" 
          @click="sendMailShow=true"><div class="sendButtonSymbol"> </div> <div class="sendButtonText">{{ $t('sendMail') }}</div></button>
        </td>
       </tr>
-->
       <tr>
        <td><div v-html="$t('table.summary.price')"></div></td>
        <td class="summaryShortTd">
         <div v-if="colorSurcharge" v-html="currencyFormat(fullCartPrice('order1') + colorSurchargeValue * colorSurcharge)"></div>
         <div v-else                v-html="currencyFormat(fullCartPrice('order2'))"></div>
        </td>
       </tr>
       <tr>
        <td><div v-html="$t('table.summary.vatPrice')"></div></td>
        <td class="summaryShortTd">
         <div v-if="colorSurcharge" v-html="currencyFormat(fullCartPriceVat('order3') + countVat(colorSurchargeValue * colorSurcharge))"></div>
         <div v-else                v-html="currencyFormat(fullCartPriceVat('order4'))"></div>
        </td>
       </tr>
       <tr>
        <td class="summaryTableGreyBg"><div v-html="$t('table.summary.fullPrice')"></div></td>
        <td class="summaryTableGreyBg summaryShortTd">
         <div v-if="colorSurcharge" v-html="currencyFormat(fullCartPriceWithVat('order5') + countWithVat(colorSurchargeValue * colorSurcharge))"></div>
         <div v-else                v-html="currencyFormat(fullCartPriceWithVat('order6'))"></div>
        </td>
       </tr>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Preview from "@/components/Preview";

export default {
  name: "WizardOrder",
  components: {
    Preview
  },
  computed: {
    ...mapState("cart", ["contents"]),
    currentIndex: {
      get() {
        return this.$store.state.cart.currentIndex;
      },
      set(value) {
        this.$store.commit("cart/setCurrentIndex", value);
      }
    },
    computedPrice() {
      if (this.colorSurcharge) {
	  return this.fullCartPrice('order1') + this.colorSurchargeValue * this.colorSurcharge;
        } else {
       return this.fullCartPrice('order2');
      }
    },
/* moved to main.js 23.08.2022 */
//    filteredFullCart() {
//      return this.fullCart.filter(item => { return item && item.variation !== null; });
//    },
//    fullCart() {
//      return this.contents;
//    },
//    colorSurcharge() {
//     let arTmp = this.filteredFullCart.filter(item => { 
//       if ((item.windowfaces.front && item.windowfaces.front.group2 == "1") || (item.windowfaces.back && item.windowfaces.back.group2 == "1")) 
//        { return true; }
//       else
//        { return false; }
//     });
//     let arTmpCountAll = arTmp.reduce(function(sum, current) { return sum + current.count; }, 0);
//     
//     if (arTmpCountAll > 0 && arTmpCountAll < 15)
//      { return true; }
//     else
//      { return false; }
//    },
//    colorSurchargeValue() {
//       if (this.$store.state.currencies.currencies[1])
//        { return this.$store.state.currencies.currencies[1].colorsurcharge; }
//       return 0;
//    },
/* ---------------------------- */
  },

  data() {
    return {
      counts: [],
      mailsend: false,
      sendMailShow: false 
    };
  },
/* moved to main.js 23.08.2022 */
  watch: {
    filteredFullCart: {
      deep: true,
      handler(value, oldValue) {
        this.$store.commit("cart/updateCartItem", oldValue, value);
      }
    },
  },
/* ---------------------------- */
  methods: {
    navigateToHome() {
     this.variation = {}; // clear object
     this.variation.type   = "";
     this.variation.price  = "";
     this.variation.width  = "";
     this.variation.height = "";
     
     location.href = '/';
    },
    cartQuantity(way, cartItem) {
      if (way == 'up')  { cartItem.count++; }
      else              { cartItem.count--; }
    },
    edit(cartItem) {
      this.loadToCurrent(cartItem);
//    this.$router.push({ name: "home" }).catch(()=>{});
      this.$router.push({ name: "size" });
    },
//    checkOrientation(cartItem, setNone = false) {
//      if(!this.hasOrientation(cartItem.variation))
//       {
//        if(setNone == 'setNone')
//         { 
//          console.log('Set none');
//          console.log(this);
//          cartItem.orientation = 'none'; 
//         }
//        return false;
//       }
//      else
//       { return true; }
//     },
    deleteItem(cartItem) {
     if (this.fullCart.length > 1) {
      this.currentIndex = 0;
      this.$store.commit('cart/removeCartItem', cartItem);
      }
     else
      {
      while(this.fullCart.length > 0) 
       { this.fullCart.pop(); }

      while(this.filteredFullCart.length > 0) 
       { this.filteredFullCart.pop(); }
      this.$store.commit('cart/removeCartItemAll');
      }
    },
    checkAcc(cartItem, type, side, cartItemNumber) {
//     console.log('------------- cartItemNumber = '+cartItemNumber+' --------------');
//     console.log(type, side, this.getMeta(cartItem, 0, cartItemNumber)[type][side], cartItem.accessories[type][side]);
//     console.log('-----------------------------------------------');
     if(typeof cartItem.accessories[type][side] !== 'undefined' && this.getMeta(cartItem, 0, cartItemNumber)[type][side])
      {
       return true;
      }
     return false;
    },
    getOrderNum(index) {
      return index + 1;
    },
    loadToCurrent(cartItem) {
      const itemIndex = this.fullCart.indexOf(cartItem);
      this.currentIndex = itemIndex;
    },
    another() {
      const lastItemIndex = this.fullCart.length - 1;
      this.variation = {}; // clear object
      this.variation.type   = "";
      this.variation.price  = "";
      this.variation.width  = "";
      this.variation.height = "";
      
      /* stop  flickering canvas (clear images) */
      document.querySelectorAll('img[id^=img]').forEach((elem) => elem.remove()); 
      document.getElementById('imgLoaded').innerHTML = "0";
      
      this.currentIndex = lastItemIndex + 1;
      this.$store.dispatch("cart/addDefaultItem");
      this.$router.push({ name: "home" });
    }
  },
  mounted() {
    this.$store.dispatch("loadAccessories", { type: "handle"});
    this.$store.dispatch("loadColors");
    this.$store.dispatch("loadColorsAccessories");
    this.$store.dispatch("loadProfiles");
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.table {
  width: 100%;

  thead th {
    font-weight: 300;
    font-size: 10px;
    text-align: center;
    border-bottom: 2px solid $border-grey;
    padding-bottom: 10px;
    padding-top: 10px;
    &.text-left {
      text-align: left;
    }
    &.text-right {
      text-align: right;
    }
  }

  td {
    vertical-align: middle;
  }
}

.table__row td {
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid $border-grey;
}

.input--number {
  width: 80px;
  height: 30px;
  border-radius: 15px;
  outline: none;
  text-align: center;
  border: 1px solid $border-grey;
}

.form-row--padded.row-redo {
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 2px solid $border-grey;
}

.table--order .variation__value {
  font-weight: 700;
}

.input--number {
  border: 1px solid $blue;
}

.order-item-mobile {
  padding: 20px;
}

.order-item-mobile__count,
.order-item-mobile__price,
.order-item-mobile__button,
.order-item-mobile__image {
  text-align: center;
  padding: 10px 0;
}

.alert {
  padding: 10px;
}
</style>
